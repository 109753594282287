<template>
  <v-container id="dashboard" fluid tag="section">

  <v-form ref="product">
    <base-material-card icon="mdi-tshirt-crew">
      <slot slot="title">Products</slot>
      <slot slot="rightButtons">
        <toggle-button text="Add a new Product" openIcon="mdi-plus"
         @toggle="showCreateProductToggle = !showCreateProductToggle" :showing="showCreateProductToggle"/>
      </slot>
      <p>If you would like to sell products alongside your tickets, for example T-Shirts, please add them here.
      </p>
      <div class="mt-3" v-if="showCreateProductToggle">
        <v-text-field label="New Product Name" v-model="newProductName" :rules="[utils.requiredRule]">
          <template v-slot:append-outer>
            <v-icon large @click="createProduct">mdi-checkbox-marked-circle</v-icon>
            <v-icon large @click="showCreateProductToggle = false">mdi-close-circle</v-icon>
          </template>
        </v-text-field>
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row class="mx-n1 underlinedRow" outline >
          <v-col cols="4">Name</v-col>
          <v-col cols="2" class="text-right">Total</v-col>
          <v-col cols="2" class="text-right">Sold</v-col>
          <v-col cols="2" class="text-right">Avail</v-col>
          <v-col cols="2" class="text-right">%Sold</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(product, i) in products" :key="i" @click="selectItem(product)">
              <v-col cols="4">{{product.name}}</v-col>
              <v-col cols="2" class="text-right">{{product.quantity}}</v-col>
              <v-col cols="2" class="text-right">{{product.sold}}</v-col>
              <v-col cols="2" class="text-right">{{product.quantity - product.sold}}</v-col>
              <v-col cols="2" class="text-right">{{percentSold(product)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row class="mx-n1 underlinedRow" outline>
          <v-col cols="4">Name</v-col>
          <v-col cols="2" class="text-right">Total Products</v-col>
          <v-col cols="2" class="text-right">Sold</v-col>
          <v-col cols="2" class="text-right">Available</v-col>
          <v-col cols="2" class="text-right">% Sold</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(product, i) in products" :key="i" @click="selectItem(product)">
              <v-col cols="4">{{product.name}}</v-col>
              <v-col cols="2" class="text-right">{{product.quantity}}</v-col>
              <v-col cols="2" class="text-right">{{product.sold}}</v-col>
              <v-col cols="2" class="text-right">{{product.quantity - product.sold}}</v-col>
              <v-col cols="2" class="text-right">{{percentSold(product)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </base-material-card>
  </v-form>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import axios from "axios";
  import Store from '@/services/Store.js';

  export default {
    name: 'Event',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      SelectImageButton: () => import('./components/SelectImageButton'),
      SelectColours: () => import('./components/SelectColours'),
    },  

    data () {
      return {
        newProductName: "",
        boxOffice: {},
        availability: {},
        products: [],
        user: {},
        showCreateProductToggle: false,
      }
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },

    created() {
      this.boxOffice.id = this.$route.params.id;
      this.load();
    },

    methods: {

      avail(product, type) {
        var avail = this.availability[product.id];
        if (avail) {
          return avail[type];
        }
      },

      percentSold(product) {
        if (product.sold) {
          return Utils.formatPercent((product.quantity - product.sold) / product.quantity);
        }
      },

      async load() {
        await this.readBoxOffice();
        await this.readProducts();
      },

      async readProducts() {
        this.products = await Api.post(this, "Product", "list", this.boxOffice);
//        var availabilities = await Api.post(this, "Event", "availability", this.event);
        var availabilities = [];
        this.availability = {};
        for (var avail of availabilities) {
          var existing = this.availability[avail.productId];
          if (!existing) {
            existing = {};
            existing.confirmedTickets = 0;
            existing.unconfirmedTickets = 0;
            existing.quantity = 0;
            existing.availableTickets = 0;
          } 
          existing.confirmedTickets += avail.confirmedTickets;
          existing.unconfirmedTickets += avail.unconfirmedTickets;
          existing.quantity += avail.quantity;
          existing.availableTickets += avail.availableTickets;
          this.availability[avail.productId] = existing;
        }
      },

      async readBoxOffice() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
      },

      async createProduct() {
        if (! await Utils.validate(this.$refs.product) ) {
          return;
        }
        var product = {}
        product.name = this.newProductName;
        product.boxOfficeId = this.boxOffice.id;
        await Api.post(this, "Product", "create", product);
        this.newProductName = "";
        this.showCreateProductToggle = false;
        this.readProducts();
      },

      selectItem(product) {
        this.$router.push("/Dashboard/Product/" + product.id)
      },
    }
  }
</script>
